import React, { useEffect } from 'react';

const InstagramEmbed = ({ url }) => {
  useEffect(() => {
    // Load Instagram's embed script
    const script = document.createElement('script');
    script.src = 'https://www.instagram.com/embed.js';
    script.async = true;
    script.onload = () => {
      window.instgrm.Embeds.process(); // Process the embeds
    };
    document.body.appendChild(script);

    return () => {
      // Cleanup script on unmount
      document.body.removeChild(script);
    };
  }, []);

  return (
    <blockquote className="instagram-media" data-instgrm-permalink={url} data-instgrm-version="13" >
      <a href={url} target="_blank" rel="noopener noreferrer">View on Instagram</a>
    </blockquote>
  );
};

export default InstagramEmbed;
