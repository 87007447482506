import Grid from "@mui/material/Grid2";
import Trending from "./trending";
import ChartData from "./chart-data";
const style = {
  position: "fixed",
  width: "23.5vw",
  overflowY: "auto",
  height: "calc(100vh - 90px)",
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
};
function RightContent() {
  return (
    <>
      <Grid container spacing={2} style={style}>
        <Grid size={12}>
          <ChartData />
        </Grid>
        <Grid size={12}>
          <Trending />
        </Grid>
      </Grid>
    </>
  );
}

export default RightContent;
