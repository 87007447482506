import * as React from "react";
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { platforms } from './web-usage-stats.ts';
import { Card, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";

export default function ChartData() {
  
  const colorPerItem = [
    { ...platforms[0], color: 'var(--green)' },
    { ...platforms[1], color: 'var(--red)'},
    { ...platforms[2], color: 'lightgray' },
  ];
  return (
    <Card variant="outlined">
      {/* <BarChart
        xAxis={[
          { scaleType: "band", data: ["Sector A", "Sector B", "Sector C"] },
        ]}
        series={[{ data: [4, 3, 5] }, { data: [1, 6, 3] }, { data: [2, 5, 6] }]}
        width={356}
        height={300}
      /> */}
       <Typography sx={{textAlign:'center',mt:2,mb:2}}>Post's Trend Analysis</Typography>
       <PieChart
          series={[
            {
              data: colorPerItem,
              arcLabel:(item) => `${item.label}`
            },
          ]}
          {...pieParams}
        />
    </Card>
  );
}

const pieParams = {
  height: 200,
  margin: { right: 5 },
  slotProps: { legend: { hidden: true } },
};