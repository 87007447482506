import Grid from "@mui/material/Grid2";
import Profile from "./profile";
import ProfileMenu from "./profile-menu";
import Gallery from "./gallery";
const style = {
  position: "fixed",
  width: "23.5vw",
  overflowY: "auto",
  height: "-webkit-fill-available",
};
function LeftContent() {
  return (
    <>
      <Grid container spacing={2} style={style}>
        <Grid size={12}>
          <Profile />
        </Grid>
        <Grid size={12}>
          <ProfileMenu />
        </Grid>
        <Grid size={12}>
          <Gallery />
        </Grid>
      </Grid>
    </>
  );
}

export default LeftContent;
