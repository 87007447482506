import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import "./timeline-events.scss";
import { Card } from "@mui/material";
import VideoDialog from "./video-dialog";
import { PlayCircle } from "@mui/icons-material";

const Timelines = [
  {
    year: "1952",
    title: "Born",
    subTitle:
      "Arun Govil was born on 12 January 1952 in Meerut, Uttar Pradesh.He spent his teenage life in Shahjahanpur, Uttar Pradesh. He got his formal education at an affiliated college of Agra University situated in Shahjahanpur, where he studied science (BSc degree).",
    url: "https://im.indiatimes.in/content/2020/Apr/arun-govils-young-look-steals-heart-of-his-fans-202004-1586879164_5ea6a656ef3d0.jpg?w=650&h=656&cc=1",
  },
  {
    year: "1975",
    title: "Moved",
    subTitle:
      "In 1975, He moved to Mumbai to join his brother's business there. After a short while, he found that he no longer enjoyed the work and decided to find something more enjoyable.",
    url: "https://www.aboutbiography.com/wp-content/uploads/2021/02/95996883_705468203595686_3851890685358530899_n1.jpg",
  },
  {
    year: "1977",
    title: "Debut",
    subTitle:
      "After doing dramas in college he decided to start acting. Govil got his first break in Indian cinema in the 1977 film Paheli, when he was introduced to Tarachand Barjatya by his sister-in-law Tabassum.[6] He made his Bollywood debut in Prashanta Nanda's Paheli (1977).[6] He shot to stardom after he was cast in Kanak Mishra's Sawan Ko Aane Do (1979) and Satyen Bose's Saanch Ko Aanch Nahin (1979).",
    url: "https://alchetron.com/cdn/paheli-1977-film-97347581-e14f-40d2-ac49-3dd1488d42f-resize-750.jpg",
  },
  {
    year: "1986",
    title: "Ramayan",
    subTitle:
      "He was then cast as Lord Rama in Sagar's TV series Ramayan (1986).His role as Rama provided him great exposure and led him to be cast in other roles such as Harishchandra in the TV series Vishwamitra or Buddha in the TV series Buddha. He lent his voice as Rama in Yugo Sako's Indo-Japanese animation film Ramayana.",
    url: "https://static.abplive.com/wp-content/uploads/sites/2/2021/01/11233532/arun-govil-1.jpg",
  },
  {
    year: "1988",
    title: "Award",
    subTitle:
      "He was then cast as Lord Rama in Sagar's TV series Ramayan (1986), for which he won the Uptron Award in the Best Actor in a Leading Role category in 1988.He reprised his role as Rama in Sagar's Luv Kush and Padmalya Telefilms Limited's Jai Veer Hanuman Barjatya was really impressed by Govil's performance in the film and he signed him a three-film-deal — Kanak Mishra's Sawan Ko Aane Do (1979), Vijay Kapoor's Raadha Aur Seeta (1979) and Satyen Bose's Saanch Ko Aanch Nahin (1979). Sawan Ko Aane Do became a major success at box office catapulting Govil to stardom",
    url: "https://resize.indiatvnews.com/en/resize/newbucket/715_-/2022/12/arun-govil-1670588020.jpg",
  },
  {
    year: "2021",
    title: "Joined BJP",
    subTitle: "On March 18, 2021, he joined the Bhartiya Janata Party.",
    url: "https://www.bjp.org/files/photo-gallery/img-20210318-wa0053.jpg",
  },
  {
    year: "2024",
    title: "Finally, return to Meerut as Member of Parliament",
    subTitle:
      "Arun Govil was nominated as the Bharatiya janta party candidate for Meerut Lok Sabha constituency from Uttar Pradesh on 25 March 2024 to contest the upcoming 2024 Lok Sabha polls.On 4 June 2024, he was elected to the Lok Sabha, representing Meerut Lok Sabha constituency.",
    url: "https://images.hindustantimes.com/telugu/img/2024/06/05/960x540/PTI06-04-2024-000463B-0_1717559797213_1717559807251.jpg",
  },
];

export default function TimelineEvents() {
  const [hoverIndex, setHoverIndex] = React.useState(false);
  const mouseEnterEvent = (event, index) => {
    console.log(event);
    if (event == true) {
      setHoverIndex(index);
    } else {
      setHoverIndex(-1);
    }
  };

  return (
    <div>
      <Timeline position="alternate" className="timeline">
        {Timelines.map((item, index) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent></TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary" className="event-time">
                <VideoDialog>
                  <div className="sub"> <PlayCircle color="primary" sx={{marginLeft:'5px'}}></PlayCircle>{item.year}</div>
                </VideoDialog>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "3rem", px: 2 }}>
              <strong>{item.title}</strong>
              <Typography className={index % 2 === 0 ? "pr-120" : "pl-120"}>
                {item.subTitle}
              </Typography>
              <div className={index % 2 === 0 ? "card-left" : "card-right"}>
                <Card className="hidden-box">
                  <img src={item.url} alt="develoment"/>
                </Card>
              </div>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </div>
  );
}
