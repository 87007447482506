import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FolderIcon from "@mui/icons-material/Folder";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import Avatar from "@mui/material/Avatar";
import { Divider } from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import BadgeIcon from "@mui/icons-material/Badge";
import ContactMailIcon from "@mui/icons-material/ContactMail";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

export default function Profile() {
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  return (
    <Card variant="outlined">
      <CardContent>
        <div className="flex align-items gap-8">
          <Avatar
            alt="Arun Govil"
            src="/images/arun_avatar.jpg"
            sx={{ width: 90, height: 90 }}
          /> 
          <Typography variant="h6" component="div">
            Arun Govil
          </Typography>
        </div>
        <Divider style={{ paddingTop: 24 }} />
        <List dense={dense}>
          <ListItem>
            <ListItemIcon>
              <PlaceIcon color="primary"/>
            </ListItemIcon>
            <ListItemText
              primary="Meerut, Uttar Pradesh, India"
              secondary={secondary ? "Secondary text" : null}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemIcon>
              <ContactMailIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Bharatiya Janata Party"
              secondary={secondary ? "Secondary text" : null}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemIcon>
              <BadgeIcon color="primary"/>
            </ListItemIcon>
            <ListItemText
              primary="Actor and politician"
              secondary={secondary ? "Secondary text" : null}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemIcon>
              <AssignmentIndIcon color="primary"/>
            </ListItemIcon>
            <ListItemText
              primary="Member of Parliament, Lok Sabha"
              secondary={secondary ? "Secondary text" : null}
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
}
