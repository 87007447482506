import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BarChartIcon from "@mui/icons-material/BarChart";
import DescriptionIcon from "@mui/icons-material/Description";
import LayersIcon from "@mui/icons-material/Layers";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import { Card, CardActions, CardContent } from "@mui/material";
import "./dashboard.scss";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import BasicLineChart from "../../components/charts/line-charts";
import BasicBars from "../../components/charts/bars-charts";
import BasicPie from "../../components/charts/pie-charts";

const NAVIGATION = [
  {
    kind: "header",
    title: "Main items",
  },
  {
    segment: "dashboard",
    title: "Dashboard",
    icon: <DashboardIcon />,
  },
  {
    segment: "orders",
    title: "Orders",
    icon: <ShoppingCartIcon />,
  },
  {
    kind: "divider",
  },
  {
    kind: "header",
    title: "Analytics",
  },
  {
    segment: "reports",
    title: "Reports",
    icon: <BarChartIcon />,
    children: [
      {
        segment: "sales",
        title: "Sales",
        icon: <DescriptionIcon />,
      },
      {
        segment: "traffic",
        title: "Traffic",
        icon: <DescriptionIcon />,
      },
    ],
  },
  {
    segment: "integrations",
    title: "Integrations",
    icon: <LayersIcon />,
  },
];

const demoTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: "data-toolpad-color-scheme",
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

function DemoPageContent({ pathname }) {
  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        margin={2}
      >
        <Grid size={3}>
          <Card variant="outlined" className="main-card">
            {" "}
            <CardContent>
              <div className="card-heading">Total Meetings</div>
              <div className="card-content">10K</div>
            </CardContent>
          </Card>
        </Grid>
        <Grid size={3}>
          <Card variant="outlined" className="main-card">
            {" "}
            <CardContent>
              <div className="card-heading">Total Events</div>
              <div className="card-content">2000</div>
            </CardContent>
          </Card>
        </Grid>
        <Grid size={3}>
          <Card variant="outlined" className="main-card">
            {" "}
            <CardContent>
              <div className="card-heading">Total Resolution</div>
              <div className="card-content">8k</div>
            </CardContent>
          </Card>
        </Grid>
        <Grid size={3}>
          <Card variant="outlined" className="main-card">
            {" "}
            <CardContent>
              <div className="card-heading">Total Jobs</div>
              <div className="card-content">10K</div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2} marginX={2}>
        <Grid size={6}>
          <Card>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar />
            </LocalizationProvider>
          </Card>
        </Grid>
        <Grid size={6}>
          <Card>
            <BasicLineChart />
          </Card>
        </Grid>
        <Grid size={6}>
          <Card>
            <BasicBars />
          </Card>
        </Grid>
        <Grid size={6}>
          <Card>
            <BasicPie />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

DemoPageContent.propTypes = {
  pathname: PropTypes.string.isRequired,
};

function DashboardLayoutBasic(props) {
  const { window } = props;

  const [pathname, setPathname] = React.useState("/dashboard");

  const router = React.useMemo(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: (path) => setPathname(String(path)),
    };
  }, [pathname]);

  // Remove this const when copying and pasting into your project.
  const demoWindow = window !== undefined ? window() : undefined;

  return (
    // preview-start
    <AppProvider
      navigation={NAVIGATION}
      router={router}
      branding={{
        title: "Meerut Connect",
      }}
      theme={demoTheme}
      window={demoWindow}
    >
      <DashboardLayout>
        <DemoPageContent pathname={pathname} />
      </DashboardLayout>
    </AppProvider>
    // preview-end
  );
}

DashboardLayoutBasic.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default DashboardLayoutBasic;
