import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

export default function Gallery() {
  return (
    <ImageList sx={{ width: '100%', height: 505 }} cols={3} rowHeight={164}>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: 'https://th.bing.com/th/id/OIP.PfR7l3pxDRdz3tlSs5InBAAAAA?w=143&h=150&c=7&r=0&o=5&dpr=1.3&pid=1.7',
    title: 'Breakfast',
  },
  {
    img: 'https://th.bing.com/th/id/OIP.0GfRknLpok9I357ABCJppQAAAA?w=136&h=147&c=7&r=0&o=5&dpr=1.3&pid=1.7',
    title: 'Burger',
  },
  {
    img: 'https://th.bing.com/th/id/OIP.8f-13TOEa4OY3inSTMJPaAHaLH?w=100&h=150&c=7&r=0&o=5&dpr=1.3&pid=1.7',
    title: 'Camera',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
  },
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
  }

];
