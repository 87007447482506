import { Diversity3 } from "@mui/icons-material";
import { Fab } from "@mui/material";
import AppointmentForm from "../pages/appointment/appointment-form";

export default function BasicFloatButton({ isVisible, setIsVisible }) {
  const style = {
    margin: 0,
    top: "auto",
    right: "auto",
    bottom: 20,
    left: 20,
    position: "fixed",
  };
  const style2 = {
    margin: 0,
    top: "auto",
    right: "auto",
    bottom: 90,
    left: 20,
    position: "fixed",
  };

  const handleCalendarClick = () => {
    setIsVisible(!isVisible);
  };

  const handleSocialClick = () => {};

  return (
    <>
      <a href="/social">
        <Fab
          color="primary"
          aria-label="add"
          style={style2}
          onClick={handleSocialClick}
        >
          <Diversity3 sx={{color:'white'}}/>
        </Fab>
      </a>
      <Fab
        color="primary"
        aria-label="add"
        style={style}
        onClick={handleCalendarClick}
      >
       <AppointmentForm isVisible={isVisible}></AppointmentForm>
      </Fab>
    </>
  );
}
