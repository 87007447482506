import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import FolderIcon from "@mui/icons-material/Folder";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Button } from "@mui/material";
export default function ShortcutsPost({ imageCallback }) {
  const [value, setValue] = React.useState("recents");
  const [imageUrl, setImageUrl] = React.useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageUrl(reader.result);
      imageCallback(reader.result);
    };

    reader.readAsDataURL(file);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    imageCallback(newValue);
  };

  return (
    <>
      <BottomNavigation
        value={value}
        onChange={handleChange}
        className="flex space-around"
      >
        <Button component="label" className="flex flex-column">
          <AddPhotoAlternateIcon />
          <input
            hidden
            accept="image/*"
            id="upload-image"
            type="file"
            onChange={handleFileUpload}
          />
          <label>Add Photo</label>
        </Button>
        <Button component="label" className="flex flex-column">
          <AttachFileIcon />
          <input hidden accept="image/*" multiple type="file" />
          <label>Add Attachment</label>
        </Button>
        <Button component="label" className="flex flex-column">
          <LocationOnIcon />
          <label>Near by</label>
        </Button>
        <Button component="label" className="flex flex-column">
          <FolderIcon />
          <label>Add Post</label>
        </Button>
      </BottomNavigation>
    </>
  );
}
