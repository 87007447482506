function HeadingSubTitle({ title, secTitle }) {
  return (
    <>
      <div className="main-head">
        <div className="line-size">
          {/* <hr className="hr-line"  style={{width: '24rem'}}/> */}
        </div>
        <div className="sub-heading">
          <span style={{ color: 'var(--main-color)' }}>{title} </span>{secTitle}
        </div>
        <div className="line-size">
          <hr className="hr-line" style={{width: '10rem'}}/>
        </div>
      </div>
    </>
  );
}

export default HeadingSubTitle;
