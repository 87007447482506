import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import HeadingTitle from "../../components/heading-title";
import "./blog.css";

export default function Blog() {
  const location = useLocation();
  const data = location.state;
  //console.log(data);
  return (
    <div className="page-container">
      <HeadingTitle
        title="Meerut 2.0"
        secTitle="- Journey of Development"
      ></HeadingTitle>
      <Card sx={{ maxWidth: 600,position:'absolute', left:'35%', height: '80vh', marginBottom:4 }}>
        <CardMedia
          component="img"
          alt="green iguana"
          height="300"
          image={data?.imgUrl}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {data?.title}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {data?.body}
          </Typography>
        </CardContent>
       
      </Card>
    </div>
  );
}
