// TwitterFeed.js
import React, { useEffect } from "react";
import "./third-page.scss";

const TwitterFeed = () => {
  useEffect(() => {
    // Load Twitter widget script once the component is mounted
    if (window.twttr) {
      window.twttr.widgets.load();
    }
  }, []);

  return (
    <a
      className="twitter-timeline"
      href="https://twitter.com/BJP4India?ref_src=twsrc%5Etfw"
    >
      Tweets by BJP
    </a>
  );
};

export default TwitterFeed;
