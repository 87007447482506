import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import SocialDashboard from "./pages/social-dashboard/social-dashboard";
import Dashboard from "./pages/dashboard/dashboard";
import Blog from "./pages/blog/blog";
export const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/social",
    element: <SocialDashboard />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
]);
