// App.js
import React, { useState } from "react";
import Footer from "./components/footer";
import FirstPage from "./pages/first-page/first-page";
import { Context } from "./utils/context";
import SnackAlert from "./components/snack-alert";
import Meerut2 from "./pages/meerut2.0/meerut-2-0";
import BasicFloatButton from "./components/basic-float-button";
import TimelineEvents from "./pages/timeline-events/timeline-events";
import HeadingTitle from "./components/heading-title";
import EventNews from "./pages/event-news/event-news";

function App() {
  const [context, setContext] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const outsideClick = () => {
    // setOut(true);
  };
  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      onClick={outsideClick}
    >
      <Context.Provider value={[context, setContext]}>
        <div className="cust-dot">
          <a href="#sec1" className="dot"></a>
          <a href="#meerut2.0" className="dot"></a>
          <a href="#timeline-events" className="dot"></a>
          <a href="#event-news" className="dot"></a>
          <a href="#footer" className="dot"></a>
        </div>
        <FirstPage></FirstPage>
        <section id="meerut2.0" className="meerut-banner">
          <HeadingTitle
            title="Meerut 2.0"
            secTitle="- Journey of Development"
          ></HeadingTitle>
          <Meerut2></Meerut2>
        </section>
        <section id="timeline-events" className="timeline-banner">
          <HeadingTitle
            title="Boomerange "
            secTitle="- Mr. Arun Govil"
          ></HeadingTitle>
          <TimelineEvents></TimelineEvents>
        </section>
        <section id="event-news" className="events-news">
          <HeadingTitle title="Event & " secTitle="News"></HeadingTitle>
          <EventNews></EventNews>
        </section>
        <section id="floting-calendar">
          <BasicFloatButton
            isVisible={isVisible}
            setIsVisible={setIsVisible}
          ></BasicFloatButton>
        </section>

        <Footer id="footer" />
        <SnackAlert></SnackAlert>
      </Context.Provider>
    </div>
  );
}

export default App;
