import React, { useContext, useEffect, useState } from "react";
import {
  Backdrop,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Zoom,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Context } from "../../utils/context";
import dayjs from "dayjs";
import "./apointment-form.css";
import BasicDateCalendar from "../../components/basic-date-calendar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import Drawer from "@mui/material/Drawer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
const appointmentSchema = Yup.object().shape({
  name: Yup.string().required("Name is required").min(2, "Name is too short"),
  reason: Yup.string().required("Reason for appointment is required"),
  priority: Yup.string().required("Priority is required"),
});

export default function AppointmentForm({ isVisible }) {
  const [formData, setFormData] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const [context, setContext] = useContext(Context);
  const [open, setOpen] = useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  useEffect(() => {
    // Set Formik field values when data from context changes
    if (context?.selectedDate) {
      //  console.log(context);
      formik.setFieldValue(
        "dateTime",
        context?.selectedDate?.format("DD-MM-YYYY") + ", " + context?.slot
      );
    }
  }, [context?.selectedDate, context?.slot]);

  const formik = useFormik({
    initialValues: {
      name: "",
      reason: "",
      priority: "",
      invitationCard: null,
      dateTime: dayjs("2024-10-10")?.format("DD-MM-YYYY") + ", 12:00 - 2:00 PM",
      inPersonMeeting: false,
    },
    validationSchema: appointmentSchema,
    onSubmit: values => {
      // fadeOpen();
      handleOpenBd();
      const formDetails = {
        name: values.name,
        reason: values.reason,
        priority: values.priority,
        invitationCard: values?.invitationCard?.name,
      };
      setFormData(formDetails);
      setConfirmation(true);
      setTimeout(() => {
        handleCloseBd();
        setState({ ...state, ["right"]: false });
      }, 4000);
      formik.resetForm();
      console.log(values);
    },
  });

  const handleCloseBd = () => {
    setOpen(false);
  };

  const handleOpenBd = () => {
    setOpen(true);
  };

  const toggleDrawer = (anchor, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      <CalendarMonth
        sx={{ color: "white" }}
        onClick={toggleDrawer("right", true)}
      />
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        <div>
          <div className="appoint-head">Book Appointment</div>
          <BasicDateCalendar></BasicDateCalendar>
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className="flex flex-column m-4"
          >
            <TextField
              size="small"
              label="Name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              margin="normal"
            />
            <TextField
              size="small"
              label="Reason for Appointment"
              name="reason"
              value={formik.values.reason}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.reason && Boolean(formik.errors.reason)}
              helperText={formik.touched.reason && formik.errors.reason}
              margin="normal"
            />
            <TextField
              select
              size="small"
              label="Priority"
              name="priority"
              value={formik.values.priority}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.priority && Boolean(formik.errors.priority)}
              helperText={formik.touched.priority && formik.errors.priority}
              margin="normal"
            >
              <MenuItem value="High">High</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="Low">Low</MenuItem>
            </TextField>
            <TextField
              fullWidth
              size="small"
              type="file"
              name="invitationCard"
              onChange={event =>
                formik.setFieldValue("invitationCard", event.target.files[0])
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.invitationCard &&
                Boolean(formik.errors.invitationCard)
              }
              helperText={
                formik.touched.invitationCard && formik.errors.invitationCard
              }
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <FormControlLabel
              value="In-Person Meeting"
              control={<Checkbox />}
              label="In-Person Meeting"
            />
            <Button
              variant="contained"
              type="submit"
              sx={{
                mt: 2,
                color: "white",
                fontSize: 17,
                fontWeight: 600,
              }}
            >
              Save
            </Button>
            {/* Overlay */}
            <div>
              {/* <Button onClick={handleOpenBd}>Show backdrop</Button> */}
              <Backdrop
                sx={theme => ({
                  color: "#fff",
                  zIndex: theme.zIndex.drawer + 1,
                  width: "371px",
                  right: 0,
                  left: "unset",
                  backgroundColor: "#000",
                  opacity: "0.7 !important",
                })}
                open={open}
                onClick={handleCloseBd}
              >
                <div style={{ textAlign: "center", margin: "2rem" }}>
                  <Zoom
                    in={open}
                    style={{ transitionDelay: open ? "500ms" : "0ms" }}
                  >
                    <CheckCircleIcon
                      style={{ color: "#00ff0d", fontSize: "4rem" }}
                    />
                  </Zoom>

                  <Typography variant="body1" display="block">
                    Thank you for apointment request, our team will reach you
                    soon.
                  </Typography>
                </div>
              </Backdrop>
            </div>
          </form>
        </div>
      </Drawer>

      {/* ))} */}
    </div>
  );
}
