import React from "react";
import PropTypes from "prop-types";
import { Box, Grid2 } from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import GppGoodIcon from "@mui/icons-material/GppGood";
import FavoriteIcon from "@mui/icons-material/Favorite";
import "./social-dashboard.css";

const UserOveriew = (props) => {
  return (
    <Box id="user-overview">
      <Grid2 container spacing={4} id="user-overview">
        <Grid2 className="card-block" size={4}>
          <div className="card-icon">
            <FavoriteIcon fontSize="large" htmlColor="var(--pink)" />
          </div>
          <Box fontWeight={700} fontSize={26}>
            16K
          </Box>
          <Box marginTop={1}>Total Posts</Box>
        </Grid2>
        <Grid2 className="card-block" size={4}>
          <div className="card-icon">
            <GppGoodIcon fontSize="large" htmlColor="var(--green)" />
          </div>
          <Box fontWeight={700} fontSize={26}>
            30K
          </Box>
          <Box marginTop={1}>Total Visits</Box>
        </Grid2>
        <Grid2 className="card-block" size={4}>
          <div className="card-icon">
            <FlagIcon fontSize="large" htmlColor="var(--red)" />
          </div>
          <Box fontWeight={700} fontSize={26}>
            1000
          </Box>
          <Box marginTop={1}>Live Users</Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};

UserOveriew.propTypes = {};

export default UserOveriew;
