import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ReactCardFlip from "react-card-flip";
import { Context } from "../utils/context";
import { useNavigate } from "react-router-dom";
import { Facebook, Share, X } from "@mui/icons-material";

export default function MediaCard({ imgUrl, title, body }) {
  const [isFlipped, setIsFlipped] = React.useState(false);
  const [context, setContext] = React.useContext(Context);
  const navigate = useNavigate();

  const onMouseEnter = () => {
    setIsFlipped(true);
    //  console.log('Enter');
  };

  const onMouseLeave = () => {
    setIsFlipped(false);
    // console.log('Exit');
  };

  const learnMoreClick = () => {
    // setContext(prevContext => ({
    //   ...prevContext,
    //   selectedData: {imgUrl:{imgUrl},title:{title},body:{body}},
    // }));
    navigate("/blog", { state: { imgUrl, title, body } });
    // console.log({imgUrl,title,body});
  };
  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
      <Card
        sx={{ mx: 2, my:2 }}
        onMouseEnter={onMouseEnter}
        // onMouseLeave={onMouseLeave}
      >
        <CardMedia sx={{ height: 250 }} image={imgUrl} title="green iguana" />
        <CardContent>
          <Typography gutterBottom variant="h6" component="div" margin={0}>
            {title}
          </Typography>
        </CardContent>
        <CardActions>
          <Facebook color="primary" sx={{ fontSize:"30px"}}></Facebook>
          <X color="primary"></X>
          <Share color="primary"></Share>
          <Button size="small" onClick={learnMoreClick}>
            Learn More
          </Button>
        </CardActions>
      </Card>
      <Card
        sx={{ mx: 2, my: 2 }}
        // onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <CardMedia sx={{ height: 250 }} image={imgUrl} title="green iguana" />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.primary" }}>
            {body}
          </Typography>
        </CardContent>
        <CardActions>
          <Facebook color="primary" sx={{ fontSize:"30px"}}></Facebook>
          <X color="primary"></X>
          <Share color="primary"></Share>
          <Button size="small" onClick={learnMoreClick} sx={{ float: "right" }}>
            Learn More
          </Button>
        </CardActions>
      </Card>
    </ReactCardFlip>
  );
}
