import { Button, Card, CardContent, IconButton, TextField } from "@mui/material";
import ShortcutsPost from "./shortcuts-post";
import { useState } from "react";
import { MoveToInbox } from "@mui/icons-material";

const style = {
  width: "calc(100% - 16px)",
  borderRadius: "4px",
  padding: "6px",
  fontSize: "18px",
};

export default function EditPost({ postCallback }) {
  const [postContent, setPostContent] = useState("");
  const [selectedImg, setSelectedImg] = useState("");
  let newPost = {
    userName: "Arun Govil",
    content: "",
    url: "",
    updateTime: "",
  };
  const imageCallback = img => {
    setSelectedImg(img);
  };

  const handleChange = event => {
    setPostContent(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0]; // Format: YYYY-MM-DD
    newPost.updateTime = formattedDate;
    newPost.url = selectedImg;
    newPost.content = postContent;
    postCallback(newPost);
    setSelectedImg("");
    setPostContent("");
  };

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };
  return (
    <>
      <Card variant="outlined">
        <CardContent style={{ padding: "8px" }}>
          <form className="flex align-start gap-8" onSubmit={handleSubmit}>
            <div className="w-100">
              <TextField
                variant="outlined"
                style={style}
                value={postContent}
                onChange={handleChange}
              />
              {selectedImg && (
                <img
                  src={selectedImg}
                  alt="Upload Post content "
                  height="100"
                />
              )}
              <ShortcutsPost imageCallback={imageCallback} />
            </div>
            <div>
              <IconButton
                aria-label="post"
                color="primary"
                onChange={handleChange}
                onKeyDown={handleKeyDown} 
                onClick={handleSubmit}
              >
                <MoveToInbox  sx={{fontSize:40}}/>
              </IconButton>
            </div>
          </form>
        </CardContent>
      </Card>
    </>
  );
}
