import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import MediaCard from "../../components/media-card";

export default function Meerut2() {
  const data = [
    {
      imgUrl: "/images/hospital.jpg",
      title: "Waste2Wonder Plant",
      body: "Transforming Meerut muncipal solid waste to bio-CBG, a clean, green and renewable fuel.By recycling organic and municipal waste, the plant reduces landfill use, which can be converted to a green space for public use. Reducing carbon emission, improving air quality.Will be  providing free bio gas to Meerut government vehicles - Ambulance, trucks, buses etc. Will be selling back the CBG to industries and government bodies(GAIL, IGL etc.) and making a profit of 3-4 crore every year. It’s a successful model running in Indore city.",
    },
    {
      imgUrl: "/images/school.jpg",
      title: "School E-Bank",
      body: "All CSR initiatives are just providing amenities(Laptops, AC’s, Tables, water-coolers) to schools but non focusing on education standards. Our plan is to improve the quality of  education by providing an online learning platform and improve the 10th 12th board results. All chapters for Physics, Maths, Chemistry, Biology, Accounts will be recorded by India’s best teachers in “HINDI”. The school teachers will then act as a of tutors. Syllabus rarely changes for government schools so this could be a lifetime thing. If it can work for Meerut schools then why can’t it work for all the UP schools. Can request Yogi ji to implement in all the schools later.",
    },
    {
      imgUrl: "/images/vikas.png",
      title: "Rog Nidan",
      body: "Plan is to uplift the Meerut government hospitals with hightech MRI, CT Scan, Ultrasound machines. We wish to reduce diagnostic cost by 90%. Lower and middle class will be directly benefited from this  project. This program aims to empower diagnostic centers of government institutes with cutting-edge technology, improving healthcare outcomes and ensuring that patients receive accurate and timely diagnoses. The minimal amount charged for the diagnostics services will be used for annual maintenance charges. LLRM college doctors/Radiologists/Students will be observing / managing the machines.",
    },
    {
      imgUrl: "/images/ramayan-theme.jpg",
      title: "Ramayan Theme Park",
      body: "It will be a unique cultural destination that brings to life the timeless epic of the Ramayana. Will boast a lot travel and tourism. Through interactive exhibits and immersive experiences, visitors will journey through the key events and moral lessons of the Ramayana. Will have adventure park and shopping complex. It’s one of kind project. Very relatable with Shri Arun Govil ji’s life and can call it his dream project.",
    },

    {
      imgUrl: "/images/wifi.jpg",
      title: "Free Wifi In Meerut",
      body: "Wifing Meerut is an innovative initiative designed to provide free Wi-Fi access to every resident of the city - our impact and visibility will be in whole of India. Empowering communities through seamless digital connectivity. Will be a direct & immediate impact on each and every person of Meerut. This project will enable the citizens of Meerut to engage with the world like never before, driving growth, innovation, and inclusivity. Only Mumbai is able to do so and Delhi(AAP Party) is still strugling. Onboarding of Public Camera’s for safety and security will be very easy then.",
    },

    {
      imgUrl: "/images/shaheed-park.jpg",
      title: "UP’s Shahid Park",
      body: "UP’s representation in Indian Army is appox. 16% but total number of shahid’s from UP are 24%(Param Vir and Maha Vir chakras). This is very striking. Land can be acquired from Meerut Cantonment via request. Will act as source of inspiration to youth & as symbol of courage. The War Memorial of UP will stand as a tribute to the brave soldiers from Uttar Pradesh who have sacrificed their lives in the service of the nation. Maintenance responsibility can also be shared with Army itself. At the time of inauguration, we can invite our honourable Defence Minister(Shri Rajnath Singh ji)",
    },
    {
      imgUrl: "/images/maharaja.jpg",
      title: "Maharaja Agrasen Temple",
      body: "The Temple will be a tribute to the revered king Maharaja Agrasen, known for his contributions to social welfare and economic equality. This temple will serve as a spiritual and cultural hub, fostering unity, community engagement, and the values of compassion. With a blend of traditional architecture and modern amenities. The temple will not only offer a place for worship but also serve as a center for cultural activities and social initiatives. In Meerut we have lot of followers of them, even revered them as God. Only Prominent Maharaja Agrasen temple is in Hisar, Haryana.",
    },

    {
      imgUrl: "/images/olympic.jpg",
      title: "Meerut Olympic Park",
      body: "Since India is preparing to host 2036 olympic, Meerut Olympic Park is an ambitious project aimed at celebrating sports excellence and fostering athletic development in the region. Shifting Meerut focus from just producing sports goods to actually making Sports Athletes. Designed to promote physical fitness and community engagement, the park will serve as a hub for both professional athletes and sports enthusiasts, positioning Meerut as a center for athletic excellence on the international stage. This will be home to 20+ international/olymic sports for whole of North India. We may get funding from Goverment of India for this project.",
    },
  ];
  return (
    <>
      <Box sx={{ marginTop: "50px" }}>
        <Typography
          variant="body1"
          sx={{
            mt: 4,
            mb: 3,
            mx: 4,
            textAlign: "justify",
          }}
        >
          <Typography
            variant="span"
            color="primary"
            sx={{ fontSize: 24, marginRight: "5px" }}
          >
            Vision:
          </Typography>
          Meerut, a city with a rich historical and cultural heritage, has
          tremendous potential for growth and development. By engaging in a
          range of Corporate Social Responsibility (CSR) activities, we aim to
          transform Meerut into a hub of progress, ensuring better healthcare,
          education, infrastructure, and opportunities for its citizens. Our
          objective is to bring dynamic change, positioning Meerut on the global
          map and showcasing its vibrant potential. This initiative is designed
          not just to improve the lives of the people but also to create a
          lasting impact that resonates beyond the region.
        </Typography>
        <Grid container spacing={1}>
          {data?.map((item, index) => {
            return (
              <Grid size={{md:4, xl:3}}>
                <MediaCard
                  imgUrl={item.imgUrl}
                  title={item.title}
                  body={item?.body}
                ></MediaCard>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
}
