import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./third-page.scss";

// import required modules
import { Pagination, Autoplay } from "swiper/modules";
import { Box, Card, CardContent, Grid2 } from "@mui/material";
import InstagramEmbed from "./InstagramEmbed";
import TwitterFeed from "./twitter-feed";

export default function ThirdPage() {
  return (
    <>
      <Grid2 container spacing={2} margin={2}>
        <Grid2 size={4}>
          <Card className="feed-size">
            <Box
              backgroundColor="var(--main-color)"
              padding={2}
              fontWeight={700}
              fontSize={18}
              color={"#fff"}
            >
              Social Video
            </Box>
            <Swiper
              autoplay={{
                delay: 4000,
                disableOnInteraction: true,
              }}
              direction={"vertical"}
              slidesPerView={2}
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, Pagination]}
              className="myslider"
            >
              <SwiperSlide>
                <iframe
                  src="https://www.youtube.com/embed/-ZHMxx1-5LU?si=RzoElGyJ7I0yoWcl"
                  referrerpolicy="strict-origin-when-cross-origin"
                ></iframe>
              </SwiperSlide>
              <SwiperSlide>
                <iframe
                  src="https://www.youtube.com/embed/z4r4j0kMAb8?si=c_mw8qp_BdSstGR5"
                  referrerpolicy="strict-origin-when-cross-origin"
                ></iframe>
              </SwiperSlide>
              <SwiperSlide>
                <iframe
                  src="https://www.youtube.com/embed/5-L1F09locA?si=KdNGH9U9wm8hnd21"
                  referrerpolicy="strict-origin-when-cross-origin"
                ></iframe>
              </SwiperSlide>
              <SwiperSlide>
                <iframe
                  src="https://www.youtube.com/embed/k1C4pU0Qin4?si=PZevmgWM9VjqyCCM"
                  referrerpolicy="strict-origin-when-cross-origin"
                ></iframe>
              </SwiperSlide>
            </Swiper>
          </Card>
        </Grid2>
        <Grid2 size={4}>
          <Card className="feed-size">
            <Box
              backgroundColor="var(--main-color)"
              padding={2}
              fontWeight={700}
              color={"#fff"}
            >
              Twitter Feed
            </Box>
            <div class="twitter-feed">
              <TwitterFeed></TwitterFeed>
            </div>
          </Card>
        </Grid2>
        <Grid2 size={4}>
          <Card className="feed-size">
            <Box
              backgroundColor="var(--main-color)"
              padding={2}
              fontWeight={700}
              color={"#fff"}
            >
              Instagram Feed
            </Box>
            <InstagramEmbed url="https://www.instagram.com/siyaramkijai"></InstagramEmbed>
          </Card>
        </Grid2>
      </Grid2>

      <div style={{ marginLeft: "46px", marginRight: "46px" }}></div>
    </>
  );
}
