import { useState } from "react";
import AlignItemsList from "../../components/align-item-list";

function EventNews() {
  const [teamEvents, setTeamEvents] = useState([
    {
      name: "Ramleela Manch - Cheif Guest",
      place: "Ramleela Groud, Meerut, U.P",
      guest: "Shri Arun Govil",
      date: "11-10-2024",
      url: "./images/arun_avatar1.jpg",
      time:"current"
    },
    {
      name: "Haridwar Flyover Inauguration",
      place: "Haridwar-Meerut Road, Meerut, U.P",
      guest: "Shri Nitin Gadkari",
      date: "25-10-2024",
      url: "./images/Nitin_avatar.jpg",
      time:"current"
    },
    {
      name: "Meerut IT Park - Inauguration",
      place: "New Commercial Area, Meerut, U.P",
      guest: "Shri Narendra Modi",
      date: "2-11-2024",
      url: "./images/Narendra_avatar1.jpg",
      time:"current"
    },
    {
      name: "Tata Motors Showroom - Inauguration",
      place: "M.G. Road, Meerut, U.P",
      guest: "Shri Arun Govil",
      date: "2-11-2024",
      url: "./images/arun_avatar1.jpg",
      time:"current"
    },
    
    {
      name: "Election Rally",
      place: "Party office, Sadar Bazar, Meerut, U.P",
      guest: "Shri Arun Govil",
      date: "02-10-2022",
      url: "./images/arun_avatar1.jpg",
      time:"past"
    },
    {
      name: "DGP, Dauther's Wedding",
      place: "Shaurya Wedding Destimation, Ghaziabad, U.P",
      guest: "Shri Arun Govil",
      date: "15-10-2024",
      url: "./images/arun_avatar1.jpg",
      time:"past"
    },
    {
      name: "Party President Meeting",
      place: "Party office, Sadar Bazar, Meerut, U.P",
      guest: "Shri JP Nadda",
      date: "02-09-2024",
      url: "./images/JP_avatar1.jpg",
      time:"past"
    },
    {
      name: "Jewellery Shop Inauguration",
      place: "Mishra Bazar, Meerut, U.P",
      guest: "Shri Arun Govil",
      date: "15-10-2024",
      url: "./images/arun_avatar1.jpg",
      time:"past"
    },
    {
      name: "Women Empowerment Week",
      place: "New Sabji Mandi, Meerut, U.P",
      guest: "Shri JP Nadda",
      date: "10-11-2024",
      url: "./images/JP_avatar1.jpg",
      time:"future"
    },
    {
      name: "New Hospital Inauguration ",
      place: "Meerut Industrial Area, Meerut, U.P",
      guest: "Shri Narendra Modi",
      date: "02-11-2024",
      url: "./images/Narendra_avatar1.jpg",
      time:"future"
    },
    {
      name: "MPL - Ribbon Cutting",
      place: "National Stadium, Meerut, U.P",
      guest: "Shri Arun Govil",
      date: "01-12-2024",
      url: "./images/arun_avatar1.jpg",
      time:"future"
    },
    {
      name: "Power Plan Bhoomi Poojan",
      place: "Gang Nehar, Meerut, U.P",
      guest: "Shri Nitin Gadkari",
      date: "05-12-2024",
      url: "./images/Nitin_avatar.jpg",
      time:"future"
    },
    
  ]);

  const refreshClick = () => {
    setTeamEvents([
      {
        name: "Showroom Inauguration",
        place: "M.G Road, Meerut, U.P",
        guest: "Shri Arun Govil",
        date: "10-12-2024",
        url: "./images/arun_avatar1.jpg",
        time:"future"
      },
      ...teamEvents,
    ]);

  //  console.log(teamEvents);
  };

  return (
    <div style={{ marginBottom: 120 }}>
      <AlignItemsList
        items={teamEvents}
        refreshClick={refreshClick}
      ></AlignItemsList>
    </div>
  );
}

export default EventNews;
