import React, { useState } from "react";
import "./first-page.css";
import "../../App.css";
import "../../utils/text-animation";
import { Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import './styles.css';

// import required modules
import { Pagination, Autoplay } from "swiper/modules";

const FirstPage = () => {
  const style = {
    margin: 0,
    top: "auto",
    right: "auto",
    bottom: 250,
    left: "25%",
    position: "absolute",
    fontWeight: "600",
    textShadow: "rgb(0, 0, 0) 4px 4px 3px",
  };

  return (
    <>
      <Swiper
        autoplay={{
          delay: 8000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
        id="sec1"
      >
        <SwiperSlide>
          <div
            id="banner"
            className="banner full-screen-mode parallax auto-center"
          >
            <TextRotate />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            id="banner"
            className="banner1 full-screen-mode parallax auto-center"
          >
            <TextRotate />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            id="banner"
            className="banner2 full-screen-mode parallax auto-center"
          >
            <TextRotate />
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default FirstPage;

const TextRotate = () => {
  return (
    <>
      <Typography
        variant="h2"
        component="h2"
        color="textcolorwhite"
        className="auto-text"
      >
        MEERUT, CITY OF
        <span
          className="txt-rotate"
          data-period="2000"
          data-rotate='[ " DEVELOPMENT", " PROSPERITY", " EDUCATION", " HEALTH" ]'
        ></span>
      </Typography>
      <div>
        <a href="#meerut2.0">
          <div class="mouse">
            <div class="container">
              <div class="chevron"></div>
              <div class="chevron"></div>
              <div class="chevron"></div>
            </div>
          </div>
        </a>
      </div>
    </>
  );
};
