import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Context } from "../utils/context";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

export default function SnackAlert() {
  const [context, setContext] = React.useContext(Context);
  const [open, setOpen] = React.useState(context?.alertStatus);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  React.useEffect(() => {
    // Set Formik field values when data from context changes
    if (context?.alertStatus) {
      // console.log(context);
      setOpen(context?.alertStatus);
      // setTimeout(() => {
      //   // After 3 seconds set the show value to false
      //   setOpen(false);
      // }, 3000);
    }
  }, [context?.alertStatus]);

  return (
    // <Dialog
    //   open={open}
    //   onClose={handleClose}
    //   aria-labelledby="alert-dialog-title"
    //   aria-describedby="alert-dialog-description"
    // >
    //   <DialogContent>
    //    <div>{context?.alertMessage} </div>
    //     <IconButton color="success" >
    //       <CheckCircle sx={{ fontSize: 90 }}></CheckCircle>
    //     </IconButton>
    //   </DialogContent>
    // </Dialog>
    <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity="success"
        variant="filled"
        sx={{ width: "100%" }}
      >
        {context?.alertMessage}
      </Alert>
    </Snackbar>
  );
}
