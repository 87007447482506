import React, { useContext, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { Card, Chip } from "@mui/material";
import {
  SentimentSatisfiedAlt,
  SentimentVeryDissatisfied,
} from "@mui/icons-material";
import { Context } from "../utils/context";

export default function BasicDateCalendar() {
  const [click, setClick] = useState(true);
  const [context, setContext] = useContext(Context);
  const [avaialbleSlots, setAvaialbleSlots] = useState([
    {
      slot: "10:00 - 12:00 PM",
      isAvaialble: true,
      label: "Avaialble",
      place: "",
      isSelected: false,
    },
    {
      slot: "12:00 - 2:00 PM",
      isAvaialble: true,
      label: "Nari Vikas Yojna",
      place: "Comunity Center, Chawniline, Meerut",
      isSelected: false,
    },
    {
      slot: "4:00 - 6:00 PM",
      isAvaialble: true,
      label: "Avaialble",
      isSelected: false,
    },
  ]);
  const dateChange = newValue => {
    setContext(prevContext => ({
      ...prevContext,
      selectedDate: newValue,
    }));
    if (click) {
      avaialbleSlots.push({
        slot: "7:00 - 10:00 PM",
        isAvaialble: true,
        label: "Peincipal's Daughter Marriage",
        isSelected: false,
      });
      setAvaialbleSlots(avaialbleSlots);
    } else {
      avaialbleSlots.pop();
      setAvaialbleSlots(avaialbleSlots);
    }
    setClick(!click);
  };

  const slotClick = item => {
    if (item?.isAvaialble)
      setContext(prevContext => ({
        ...prevContext,
        slot: item?.slot,
      }));
    setAvaialbleSlots(prevSlots =>
      prevSlots.map(obj =>
        obj.slot === item?.slot ? { ...obj, isSelected: true } : { ...obj, isSelected: false }
      )
    );

    // document.getElementById('appointment').scrollIntoView()
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          disablePast={true}
          onChange={newValue => dateChange(newValue)}
          slotProps={{ calendarHeader: { sx: { color: 'var(--main-color)' } } }}
        />
      </LocalizationProvider>
      <div className="slot">Choose Slot</div>
      <div style={{ width: 320 }}>
        {avaialbleSlots.map((item, index) => (
          <Chip
            key={index}
            icon={
              item?.isAvaialble ? (
                <SentimentSatisfiedAlt />
              ) : (
                <SentimentVeryDissatisfied />
              )
            }
            label={item?.slot}
            color={item?.isAvaialble ? "success" : "error"}
            variant={item?.isSelected ? "" : "outlined"}
            sx={{ margin: "4px" }}
            onClick={() => {
              slotClick(item);
            }}
          />
        ))}
      </div>
    </>
  );
}
