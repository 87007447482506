import * as React from "react";
import './date-item.css'
export default function DateItem({day,month,year}) {

    const months = [
        "January", "February", "March", "April", "May", "June", 
        "July", "August", "September", "October", "November", "December"
    ];
    

  return (
    <div class="date-card">
    <div class="day">{day}</div>
    <div>
      <div class="month">{months[month-1]}</div>
      <div class="year">{year}</div>
    </div>
    </div>
  );
}
