import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box, Card, Chip, Grid2, IconButton, Stack } from "@mui/material";

import ThirdPage from "../pages/third-page/third-page";
import { Event, Place, RefreshSharp, Share } from "@mui/icons-material";
import HeadingSubTitle from "./heading-sub-title";
import DateItem from "./date-item/date-item";

export default function AlignItemsList({ items, refreshClick }) {
  return (
    //
    <>
      <Grid2 container spacing={2} margin={2}>
        <Grid2 size={4}>
          <Card>
            <Box
              backgroundColor="var(--main-color)"
              paddingX={2}
              paddingY={1}
              fontWeight={700}
              color={"#fff"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <div>Current Events</div>
              <IconButton sx={{ float: "right" }} onClick={refreshClick}>
                <RefreshSharp htmlColor="#fff" sx={{visibility:'hidden'}}></RefreshSharp>
              </IconButton>
            </Box>

            {items?.filter((el)=>el?.time === 'current')?.map((item, index) => {
              return (
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    paddingBottom: 0,
                  }}
                  key={index}
                >
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        sx={{ width: 90, height: 110, borderRadius: 3 }}
                        alt="Remy Sharp"
                        src={item?.url}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box display={"flex"} justifyContent={"space-between"}>
                          {item?.name}
                          <span>
                            <Share
                              color="primary"
                              sx={{ fontSize: 17, cursor: "pointer" }}
                            ></Share>
                          </span>
                        </Box>
                      }
                      primaryTypographyProps={{
                        fontSize: 20,
                        fontWeight: "medium",
                        letterSpacing: 0,
                        ml: 2,
                      }}
                      secondary={
                        <React.Fragment>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"flex-start"}
                            marginBottom={1}
                          >
                            <Typography
                              component="h3"
                              variant="body2"
                              fontWeight={600}
                              sx={{
                                color: "secondary.main",
                                display: "inline",
                                ml: 2,
                                fontSize: "14px",
                              }}
                            >
                              {item?.guest}
                            </Typography>
                            <Typography
                              component="h5"
                              variant="body2"
                              display={"flex"}
                              sx={{
                                color: "#555555",
                                display: "inline",
                                ml: 2,
                              }}
                            >
                              <DateItem
                                day={item?.date.split("-")[0]}
                                month={item?.date.split("-")[1]}
                                year={item?.date.split("-")[2]}
                              ></DateItem>
                            </Typography>
                          </Box>
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{ marginLeft: "15px" }}
                            display={"flex"}
                            flexDirection={"column"}
                          >
                            <Chip
                              label={item?.place}
                              color="primary"
                              variant="outlined"
                              icon={
                                <Place
                                  fontSize="medium"
                                  sx={{ color: "white" }}
                                />
                              }
                            />
                          </Stack>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  {items.length !== index + 1 ? (
                    <Divider
                      variant="fullWidth"
                      component="li"
                      sx={{ bgcolor: "var(--main-color)" }}
                    ></Divider>
                  ) : (
                    ""
                  )}
                </List>
              );
            })}
          </Card>
        </Grid2>
        <Grid2 size={4}>
          <Card>
            <Box
              backgroundColor="var(--main-color)"
              paddingX={2}
              paddingY={1}
              fontWeight={700}
              color={"#fff"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <div>Upcoming Events</div>
              <IconButton sx={{ float: "right" }} onClick={refreshClick}>
                <RefreshSharp htmlColor="#fff"></RefreshSharp>
              </IconButton>
            </Box>
            {items?.filter((el)=>el?.time === 'future')?.map((item, index) => {
              return (
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    paddingBottom: 0,
                  }}
                  key={index}
                >
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        sx={{ width: 90, height: 110, borderRadius: 3 }}
                        alt="Remy Sharp"
                        src={item?.url}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box display={"flex"} justifyContent={"space-between"}>
                          {item?.name}
                          <span>
                            <Share
                              color="primary"
                              sx={{ fontSize: 17, cursor: "pointer" }}
                            ></Share>
                          </span>
                        </Box>
                      }
                      primaryTypographyProps={{
                        fontSize: 20,
                        fontWeight: "medium",
                        letterSpacing: 0,
                        ml: 2,
                      }}
                      secondary={
                        <React.Fragment>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"flex-start"}
                            marginBottom={1}
                          >
                            <Typography
                              component="h3"
                              variant="body2"
                              fontWeight={600}
                              sx={{
                                color: "secondary.main",
                                display: "inline",
                                ml: 2,
                                fontSize: "14px",
                              }}
                            >
                              {item?.guest}
                            </Typography>
                            <Typography
                              component="h5"
                              variant="body2"
                              display={"flex"}
                              sx={{
                                color: "#555555",
                                display: "inline",
                                ml: 2,
                              }}
                            >
                              <DateItem
                                day={item?.date.split("-")[0]}
                                month={item?.date.split("-")[1]}
                                year={item?.date.split("-")[2]}
                              ></DateItem>
                            </Typography>
                          </Box>
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{ marginLeft: "15px" }}
                            display={"flex"}
                            flexDirection={"column"}
                          >
                            <Chip
                              label={item?.place}
                              color="primary"
                              variant="outlined"
                              icon={
                                <Place
                                  fontSize="medium"
                                  sx={{ color: "white" }}
                                />
                              }
                            />
                          </Stack>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  {items.length !== index + 1 ? (
                    <Divider
                      variant="fullWidth"
                      component="li"
                      sx={{ bgcolor: "var(--main-color)" }}
                    ></Divider>
                  ) : (
                    ""
                  )}
                </List>
              );
            })}
          </Card>
        </Grid2>
        <Grid2 size={4}>
          <Card>
            <Box
              backgroundColor="var(--main-color)"
              paddingX={2}
              paddingY={1}
              fontWeight={700}
              color={"#fff"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <div>Past Events</div>
              <IconButton sx={{ float: "right" }} onClick={refreshClick}>
                <RefreshSharp htmlColor="#fff" sx={{visibility:'hidden'}}></RefreshSharp>
              </IconButton>
            </Box>
            {items?.filter((el)=>el?.time === 'past')?.map((item, index) => {
              return (
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    paddingBottom: 0,
                  }}
                  key={index}
                >
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        sx={{ width: 90, height: 110, borderRadius: 3 }}
                        alt="Remy Sharp"
                        src={item?.url}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box display={"flex"} justifyContent={"space-between"}>
                          {item?.name}
                          <span>
                            <Share
                              color="primary"
                              sx={{ fontSize: 17, cursor: "pointer" }}
                            ></Share>
                          </span>
                        </Box>
                      }
                      primaryTypographyProps={{
                        fontSize: 20,
                        fontWeight: "medium",
                        letterSpacing: 0,
                        ml: 2,
                      }}
                      secondary={
                        <React.Fragment>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"flex-start"}
                            marginBottom={1}
                          >
                            <Typography
                              component="h3"
                              variant="body2"
                              fontWeight={600}
                              sx={{
                                color: "secondary.main",
                                display: "inline",
                                ml: 2,
                                fontSize: "14px",
                              }}
                            >
                              {item?.guest}
                            </Typography>
                            <Typography
                              component="h5"
                              variant="body2"
                              display={"flex"}
                              sx={{
                                color: "#555555",
                                display: "inline",
                                ml: 2,
                              }}
                            >
                              <DateItem
                                day={item?.date.split("-")[0]}
                                month={item?.date.split("-")[1]}
                                year={item?.date.split("-")[2]}
                              ></DateItem>
                            </Typography>
                          </Box>
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{ marginLeft: "15px" }}
                            display={"flex"}
                            flexDirection={"column"}
                          >
                            <Chip
                              label={item?.place}
                              color="primary"
                              variant="outlined"
                              icon={
                                <Place
                                  fontSize="medium"
                                  sx={{ color: "white" }}
                                />
                              }
                            />
                          </Stack>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  {items.length !== index + 1 ? (
                    <Divider
                      variant="fullWidth"
                      component="li"
                      sx={{ bgcolor: "var(--main-color)" }}
                    ></Divider>
                  ) : (
                    ""
                  )}
                </List>
              );
            })}
          </Card>
        </Grid2>
      </Grid2>

      <HeadingSubTitle title="Social" secTitle="Stream"></HeadingSubTitle>

      <ThirdPage></ThirdPage>
    </>
  );
}
