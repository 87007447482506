import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import MediaCard from "../../components/media-card";
import AppTopBar from "./app-topbar";
import LeftContent from "./left-content/left-content";
import RightContent from "./right-content/right-content";
import MainContent from "./main-content/main-content";

export default function SocialDashboard() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid size={12}>
          <AppTopBar />
        </Grid>
        <Grid container size={12} marginX={2} marginTop={'68px'}>
          <Grid size="grow" >
            <LeftContent />
          </Grid>
          <Grid size={6}>
            <MainContent />
          </Grid>
          <Grid size="grow">
            <RightContent />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
