import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import { Divider } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import NewspaperIcon from '@mui/icons-material/Newspaper';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles("dark", {
    backgroundColor: "rgba(255, 255, 255, .05)",
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function ProfileMenu() {
  const [expanded, setExpanded] = React.useState("panel1");
  const [secondary, setSecondary] = React.useState(false);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Upcoming Events</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List dense={true} style={{ padding: 0 }}>
            <ListItem>
              <ListItemIcon>
                <EventIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="22 Oct 2024 (Augarnath Temple)"
                secondary={secondary ? "Secondary text" : null}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <EventIcon color="primary"/>
              </ListItemIcon>
              <ListItemText
                primary="30 Oct 2024 (Suraj Kund Park)"
                secondary={secondary ? "Secondary text" : null}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <EventIcon color="primary"/>
              </ListItemIcon>
              <ListItemText
                primary="2 Nov 2024 (Shahid Smarak)"
                secondary={secondary ? "Secondary text" : null}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <EventIcon color="primary"/>
              </ListItemIcon>
              <ListItemText
                primary="22 Nov 2024 (Gandhi Bagh)"
                secondary={secondary ? "Secondary text" : null}
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Event history</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List dense={true} style={{ padding: 0 }}>
            <ListItem>
              <ListItemIcon>
                <NewspaperIcon color="primary"/>
              </ListItemIcon>
              <ListItemText
                primary="Biggest tourist place in Bagpat, check for more updates."
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <NewspaperIcon color="primary"/>
              </ListItemIcon>
              <ListItemText
                primary="BJP’s Arun Govil wins from Meerut Lok Sabha seat"
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <NewspaperIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="BJP candidate from Meerut Arun Govil to file his nomination"
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <NewspaperIcon color="primary"/>
              </ListItemIcon>
              <ListItemText
                primary="Meerut Chunav Result 2024"
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Development</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography>
            Bagpat's Pura Mhadev village is the best tourist attraction these days.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
