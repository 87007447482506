import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import { Divider } from "@mui/material";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255, .05)',
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function Trending() {
  const [expanded, setExpanded] = React.useState('panel1');
  const [secondary, setSecondary] = React.useState(false);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Trending News</Typography>
        </AccordionSummary>
        <AccordionDetails>
      <List dense={true} style={{padding:0}}>
        <ListItem>
          <ListItemIcon>
            <TrendingUpIcon color='success' />
          </ListItemIcon>
          <ListItemText
            primary="Yogi's next step to uplift the Bhagwa Sants to provide more security."
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemIcon>
            <TrendingUpIcon color='success' />
          </ListItemIcon>
          <ListItemText
            primary="On camera: Girl rescued after falling into water-filled pit in Uttar Pradesh"
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemIcon>
            <TrendingDownIcon color='error' />
          </ListItemIcon>
          <ListItemText
            primary="Tiger attach to be continue in Lakheempur."
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemIcon>
            <TrendingUpIcon color='success' />
          </ListItemIcon>
          <ListItemText
            primary="BJP to comeup with next master storke for election "
          />
        </ListItem>
      </List>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Latest News</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Baghpat: Accident in Bagpat of aggriculture office stunned everyone.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          UP News: New highways to be completed before election.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

