import Grid from "@mui/material/Grid2";
import DisplayPost from "./display-post";
import EditPost from "./edit-post";
import { useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import UserOveriew from "../user-overview";
const contentList = [
  {
    userName: "Arun Govil",
    content:
      "Parliament Session 2024: BJP MP (Meerut ) Arun Govil takes oath as Member of Parliament",
    url: "https://www.youtube.com/embed/z4r4j0kMAb8?si=gZ-cSWj6JI5A53fQ&amp;controls=0",
    updateTime: "2024 Oct 29",
  },
  {
    userName: "Arun Govil",
    content: `Meerut: Actor Arun Govil, famous as Lord Ram in the TV world, has achieved his first electoral victory. Arun Govil, who was contesting on a BJP ticket from Meerut-Hapur Lok Sabha seat, won by a narrow margin amid a tough competition. However, seeing his popularity in the initial phase of the election, the Bharatiya Janata Party gave him a ticket from Meerut, on which he contested the election. According to the election results that came on Tuesday, Arun Govil got a total of 5 lakh 46 thousand 469 votes. At the same time, India Alliance (Samajwadi Party) candidate Sunita Verma got 5 lakh 34 thousand 884 votes. While BSP candidate Devvrat Kumar Tyagi got 87 thousand 25 votes. Arun Govil won by a margin of just 10 thousand 585 votes.`,
    url: "/images/arun.jpg",
    updateTime: "2024 Oct 27",
  },
  {
    userName: "Arun Govil",
    content: "Parliament Session 2024",
    url: "/images/arun1.jpg",
    updateTime: "2024 Oct 20",
  },
];
function MainContent() {
  const [newtItem, setNewtItem] = useState("");
  const [open, setOpen] = useState(false);

  const postCallback = item => {
    contentList.push(item);
    setNewtItem(contentList.reverse());
    handleClick();
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <Grid container spacing={0}>
        <Grid size={12}>
          <EditPost postCallback={postCallback} />
          <UserOveriew />
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              Post published
            </Alert>
          </Snackbar>
        </Grid>
        <Grid size={12}>
          <DisplayPost contentList={contentList} />
        </Grid>
      </Grid>
    </>
  );
}

export default MainContent;
